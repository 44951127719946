import React, { FC } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
`

const PageContainer = styled.div`
  min-width: 320px;
  max-width: 1280px;
  position: relative;
  margin: 0 auto;
  background-repeat: no-repeat;
`

export const Layout: FC = ({ children, ...props }) => {
  return (
    <Container {...props}>
      <PageContainer>{children}</PageContainer>
    </Container>
  )
}
