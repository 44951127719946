import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import { Toolbar } from '@material-ui/core'
import React, { VFC } from 'react'
import { Helmet } from 'react-helmet-async'

import dfhLogo from '../../../images/designsForHealthLogo.png'
import { useAuthCallback } from '../../../lib/auth'
import { Layout } from '../Layout'
import * as Styled from './styled'

// Fix for client only routes flashing 404 on refresh
const browser = typeof window !== 'undefined' && window

export const NotFoundPage: VFC = () => {
  const { returnToCallback, isValidCallback } = useAuthCallback()
  return (
    <>
      <Helmet title="404: Not Found" />
      <GlobalStyle includeTypography />
      <Styled.TopBar>
        <Toolbar>
          {isValidCallback ? (
            <Styled.LogoButton onClick={returnToCallback}>
              <Styled.LogoImage alt="Designs for Health" src={dfhLogo} />
            </Styled.LogoButton>
          ) : (
            <Styled.LogoImage alt="Designs for Health" src={dfhLogo} />
          )}
        </Toolbar>
      </Styled.TopBar>
      <Layout>
        {browser && (
          <Styled.Container>
            <h1>Page not found.</h1>
            <p>The page you&#39;re looking for doesn&#39;t exist.</p>
            {isValidCallback && <Styled.Button onClick={returnToCallback} text="Return to Store" />}
          </Styled.Container>
        )}
      </Layout>
    </>
  )
}
