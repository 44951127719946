import { ButtonLink, PrimaryButton } from '@designsforhealth/dfh-react-components'
import { AppBar } from '@material-ui/core'
import styled from 'styled-components'

export const TopBar = styled(AppBar)`
  && {
    height: 65px;
    position: relative;
  }
`

export const LogoButton = styled(ButtonLink)`
  display: block;
  margin: 0 auto;
`

export const LogoImage = styled.img`
  margin: 0 auto;
  width: 193px;
`

export const Container = styled.div`
  color: #ffffff;
  min-height: calc(60vh);
  padding: 80px 15px 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Button = styled(PrimaryButton)`
  display: block;
  width: 100%;
  max-width: 344px;
`
